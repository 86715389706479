import { BankOutlined } from '@ant-design/icons';
import { FormGroup } from 'components';
import { FormGroupItem } from 'components/FormGroupItem';
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { styled } from 'theme';
import type { Firebase } from 'types/firebase';
import { FormFieldType } from '..';
import { CONTRACT_OPTIONS } from '../../../../constants';
import * as Styles from '../MerchantDetailsForm/MerchantDetailsForm.styles';

interface MerchantAcquirerForm {
  merchant: Firebase.Merchant;
  onSave: (
    inputName: string,
    newAcquirerMerchantId: string | number,
    forced: boolean,
  ) => void;
}

/**
 * Styling
 */
export const Root = styled.div`
  margin-bottom: 64px;
`;

const MerchantAcquirerForm: FunctionComponent<MerchantAcquirerForm> = ({
  merchant,
  onSave,
}) => {
  const [forcingClearhausMerchantId, setForcingClearhausMerchantId] = useState(
    false,
  );

  useEffect(() => {
    if (merchant.clearhausKYCdone) {
      setForcingClearhausMerchantId(false);
    }
  }, [merchant.clearhausKYCdone]);

  const handleClearhausMerchantId = useCallback(
    () => setForcingClearhausMerchantId(true),
    [],
  );

  const alertDescription = useMemo(
    () => (
      <Fragment>
        It seems the Clearhaus merchant id is not correct,
        <span
          onClick={handleClearhausMerchantId}
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
        >
          click here if the Clearhaus Merchant Id is correct
        </span>
      </Fragment>
    ),
    [handleClearhausMerchantId],
  );
  const cannotChangeMerchantClearhausId = useCallback((): boolean => {
    //Check if every field is truthy
    return ![
      merchant.swift,
      merchant.iban,
      merchant.mcc,
      merchant.merchantContract,
    ].every(Boolean);
  }, [merchant.swift, merchant.iban, merchant.mcc, merchant.merchantContract]);

  const merchantApproved = useCallback((): boolean => {
    return merchant.clearhausKYCdone ? true : false;
  }, [merchant.clearhausKYCdone]);

  const formFields = useMemo<Array<FormFieldType>>(
    () => [
      {
        title: 'Acquirer',
        icon: <BankOutlined />,
        inputs: [
          {
            label: 'Clearhaus Merchant Id',
            alternativeLabel: 'Change Clearhaus Merchant Id',
            name: 'clearhausMerchantId',
            value: merchant.clearhausMerchantId,
            isValid: (value: string | number) => {
              const lengthSeven = Boolean(
                value.toString().trim()?.length === 7,
              );
              const isANumber = !isNaN(Number(value.toString()));
              return forcingClearhausMerchantId || (lengthSeven && isANumber);
            },
            alertDescription,
            restrictEdit:
              cannotChangeMerchantClearhausId() || merchantApproved(),
          },
          {
            label: 'MCC',
            alternativeLabel: 'Change MCC',
            name: 'mcc',
            value: merchant.mcc,
            isValid: (value: string | number) => {
              const lengthFour = Boolean(value.toString().trim()?.length === 4);
              const isANumber = !isNaN(Number(value.toString()));
              return lengthFour && isANumber;
            },
          },
          {
            label: 'Merchant Contract',
            alternativeLabel: 'Change Merchant Contract',
            name: 'merchantContract',
            value: merchant.merchantContract,
            type: 'dropdown',
            restrictEdit: merchantApproved(),
            dropdown: {
              placeholder: 'Select a contract',
              values: CONTRACT_OPTIONS,
              defaultValue: 'softpos_q3_2022_dk',
              options: CONTRACT_OPTIONS,
            },
          },
        ],
      },
    ],
    [
      alertDescription,
      forcingClearhausMerchantId,
      merchant.clearhausMerchantId,
      merchant.mcc,
      merchant.iban,
      merchant.swift,
      merchant.merchantContract,
      merchant.clearhausKYCdone,
    ],
  );

  return (
    <div>
      {formFields.map((form) => (
        <Styles.FormContainer key={form.title}>
          <FormGroup icon={form.icon} title={form.title}>
            {form.inputs.map((input) => (
              <FormGroupItem
                key={input.name}
                {...input}
                onSave={(newAcquirerMerchantId) => {
                  onSave(
                    input.name,
                    newAcquirerMerchantId,
                    forcingClearhausMerchantId,
                  );
                  setForcingClearhausMerchantId(false);
                }}
              />
            ))}
          </FormGroup>
        </Styles.FormContainer>
      ))}
    </div>
  );
};

export { MerchantAcquirerForm };

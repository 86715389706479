import { Firebase } from 'types/firebase';
import { SelectOption } from 'utils/OptionUtils';

type PaymentTypeSelectOptions = {
  label: string;
  value: Firebase.MerchantPaymentType;
};

export const MERCHANT_SEGMENTS: SelectOption[] = [
  {
    value: 'daily',
    label: 'daily',
  },
  {
    value: 'weekly',
    label: 'weekly',
  },
  {
    value: 'events/seasonal',
    label: 'events/seasonal',
  },
];

export const MERCHANT_PAYMENT_TYPE: PaymentTypeSelectOptions[] = [
  {
    label: 'In-store',
    value: 'IN_STORE',
  },
  {
    label: 'E-com',
    value: 'E_COM',
  },
  {
    label: 'Payment links',
    value: 'PAYMENT_LINKS',
  },
  {
    label: 'In-store and payment links',
    value: 'IN_STORE_AND_PAYMENT_LINKS',
  },
  {
    label: 'All payment methods',
    value: 'IN_STORE_AND_ECOM',
  },
];
export const MERCHANT_PRODUCT_TYPE: SelectOption[] = [
  {
    label: 'Physical products',
    value: 'PHYSICAL',
  },
  {
    label: 'Services or digital products',
    value: 'DIGITAL',
  },
];

export const BINARY_OPTIONS: SelectOption[] = [
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  },
];
export const AVERAGE_DELIVERY_TIME_OPTIONS: SelectOption[] = [
  {
    label: 'Less than 5 days',
    value: 'LT_5',
  },
  {
    label: '5-10 days',
    value: '5_10',
  },
  {
    label: '10-20 days',
    value: '10_20',
  },
  {
    label: 'More than 20 days',
    value: 'GT_20',
  },
];

export const CAMPAIGN_OPTIONS: SelectOption[] = [
  {
    label: 'No campaign',
    value: 'no-campaign',
  },
  {
    label: '100% QP',
    value: '100-p-qp',
  },
];

export const CONTRACT_OPTIONS: SelectOption[] = [
  {
    label: 'softpos_intro_es',
    value: 'softpos_intro_es',
  },
  {
    label: 'quickpay_intro_offer',
    value: 'quickpay_intro_offer',
  },
  {
    label: 'softpos_default_dk',
    value: 'softpos_default_dk',
  },
  {
    label: 'octopus',
    value: 'octopus',
  },
  {
    label: 'steam_fosss',
    value: 'steam_fosss',
  },
  {
    label: 'rikki_tikki_shop',
    value: 'rikki_tikki_shop',
  },
  {
    label: 'flotte_gulve',
    value: 'flotte_gulve',
  },
  {
    label: 'trailer_gaarden',
    value: 'trailer_gaarden',
  },
  {
    label: 'bolighuset_werenberg',
    value: 'bolighuset_werenberg',
  },
  {
    label: 'softpos_q3_2022_dk',
    value: 'softpos_q3_2022_dk',
  },
  {
    label: 'softpos_q3_2022_dk_no_flat',
    value: 'softpos_q3_2022_dk_no_flat',
  },
  {
    label: 'softpos_terminal_fo',
    value: 'softpos_terminal_fo',
  },
  {
    label: 'roccamore_dk',
    value: 'roccamore_dk',
  },
  {
    label: 'softpos_q3_2022_es',
    value: 'softpos_q3_2022_es',
  },
  {
    label: 'sos_dk_auto',
    value: 'sos_dk_auto',
  },
  {
    label: 'slopestar',
    value: 'slopestar',
  },
  {
    label: 'el_ekspert_dk',
    value: 'el_ekspert_dk',
  },
  {
    label: 'collectors_cage',
    value: 'collectors_cage',
  },
  {
    label: 'blomsten_hornbaek',
    value: 'blomsten_hornbaek',
  },
  {
    label: 'partex',
    value: 'partex',
  },
  {
    label: 'isoteket',
    value: 'isoteket',
  },
  {
    label: 'lions',
    value: 'lions',
  },
  {
    label: 'europa_mundo',
    value: 'europa_mundo',
  },
  {
    label: 'meer',
    value: 'meer',
  },
  {
    label: 'benefit',
    value: 'benefit',
  },
  {
    label: 'bornholm_tours',
    value: 'bornholm_tours',
  },
  {
    label: 'aldente',
    value: 'aldente',
  },
  {
    label: 'ruter_as',
    value: 'ruter_as',
  },
  {
    label: 'h9',
    value: 'h9',
  },
  {
    label: 'rosenholm',
    value: 'rosenholm',
  },
  {
    label: 'aldente1',
    value: 'aldente1',
  },
  {
    label: 'espos',
    value: 'espos',
  },
  {
    label: 'slusen',
    value: 'slusen',
  },
  {
    label: 'aldente2_yearly',
    value: 'aldente2_yearly',
  },
  {
    label: 'aldente2_monthly',
    value: 'aldente2_monthly',
  },
  {
    label: 'aldente3_yearly',
    value: 'aldente3_yearly',
  },
  {
    label: 'aldente4_yearly',
    value: 'aldente4_yearly',
  },
  {
    label: 'aldente5_yearly',
    value: 'aldente5_yearly',
  },
  {
    label: 'dentist_yearly',
    value: 'dentist_yearly',
  },
  {
    label: 'easyme',
    value: 'easyme',
  },
  {
    label: 'advisoa1',
    value: 'advisoa1',
  },
  {
    label: 'advisoa2',
    value: 'advisoa2',
  },
  {
    label: 'advisoa_ecom',
    value: 'advisoa_ecom',
  },
  {
    label: 'fo_revision',
    value: 'fo_revision',
  },
  {
    label: 'dentist_1',
    value: 'dentist_1',
  },
  {
    label: 'suit_club',
    value: 'suit_club',
  },
  {
    label: 'dinero',
    value: 'dinero',
  },
  {
    label: 'launch_2023',
    value: 'launch_2023',
  },
  {
    label: 'appi_instore',
    value: 'appi_instore',
  },
  {
    label: 'softpos_069_285_dk',
    value: 'softpos_069_285_dk',
  },
  {
    label: 'advisoa_hairbyregina',
    value: 'advisoa_hairbyregina',
  },
  {
    label: 'kohinoor',
    value: 'kohinoor',
  },
  {
    label: 'es_mc_campaign_q4',
    value: 'es_mc_campaign_q4',
  },
  {
    label: 'octopus_059_079_285',
    value: 'octopus_059_079_285',
  },
  {
    label: 'growth_2023',
    value: 'growth_2023',
  },
  {
    label: 'techsoup_079_199_027',
    value: 'techsoup_079_199_027',
  },
  {
    label: 'dinero_079_285',
    value: 'dinero_079_285',
  },
  {
    label: 'wsy_089_199_049',
    value: 'wsy_089_199_049',
  },
  {
    label: 'mic_069_199_285',
    value: 'mic_069_199_285',
  },
  {
    label: 'we_serve_you',
    value: 'we_serve_you',
  },
  {
    label: 'saldi_launch',
    value: 'saldi_launch',
  },
  {
    label: 'mic',
    value: 'mic',
  },
  {
    label: 'dk_softpos_friends_offer',
    value: 'dk_softpos_friends_offer',
  },
  {
    label: 'ja_pay',
    value: 'ja_pay',
  },
  {
    label: 'techotel',
    value: 'techotel',
  },
  {
    label: 'saldi',
    value: 'saldi',
  },
  {
    label: 'no_partner',
    value: 'no_partner',
  },
  {
    label: 'pck',
    value: 'pck',
  },
  {
    label: 'jits',
    value: 'jits',
  },
  {
    label: 'zimsystem',
    value: 'zimsystem',
  },
  {
    label: 'aldente_setup_0_subs_249',
    value: 'aldente_setup_0_subs_249',
  },
  {
    label: 'aldente_setup_0_subs_0',
    value: 'aldente_setup_0_subs_0',
  },
  {
    label: 'aldente_setup_1499_subs_249',
    value: 'aldente_setup_1499_subs_249',
  },
  {
    label: 'minuba',
    value: 'minuba',
  },
  {
    label: 'terminalfo',
    value: 'terminal_fo',
  },
  {
    label: 'information_edge',
    value: 'information_edge',
  },
];
